import { Widget } from '@typeform/embed-react';
import './App.css';

function App() {
  return (
    <div className="App">
      <Widget
        id={process.env.REACT_APP_FORM_ID}
        style={{ width: '100%', height: '100vh' }}
      />
    </div>
  );
}

export default App;
